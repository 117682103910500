import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { AuthStore } from '@core/stores/auth.store';
import { ProjectsService } from '@core/services/projects.service';
import { ScaleProject, ProjectAndSenarios } from '@core/models/project.model';
import { HttpState } from '@core/types/http';
import { HttpStatus } from '@core/enums/http-status';

import { tapResponse } from '@ngrx/operators';
import { ComponentStore } from '@ngrx/component-store';
import {
  EMPTY,
  combineLatest,
  first,
  forkJoin,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

type State = HttpState<
  Record<ScaleProject['ProjectNumber'], ProjectAndSenarios>
>;

@Injectable({ providedIn: 'root' })
export class ProjectsStore extends ComponentStore<State> {
  public readonly all$ = combineLatest([
    this.state$,
    this.state$.pipe(
      mergeMap((projects) =>
        forkJoin(
          Object.values(projects.data ?? {}).map((p) =>
            this.authStore.canAccessProject(p.details.ProjectNumber).pipe(
              first(),
              map((canAccess) => ({
                ...p,
                details: { ...p.details, myProject: canAccess },
              }))
            )
          )
        )
      ),
      map((projects) =>
        projects.reduce(
          (acc, project) => ({
            ...acc,
            [project.details.ProjectNumber]: project,
          }),
          {} as Record<ScaleProject['ProjectNumber'], ProjectAndSenarios>
        )
      )
    ),
  ]).pipe(map(([state, data]) => ({ ...state, data })));

  public readonly loading$ = this.select(
    ({ status }) => status === HttpStatus.Loading
  );

  public selectByProjectNumber(projectNumber: ScaleProject['ProjectNumber']) {
    return this.select((state) => ({
      ...state,
      data: state.data?.[projectNumber],
    }));
  }

  public readonly load = this.effect<boolean>((invalidateCahce$) =>
    invalidateCahce$.pipe(
      withLatestFrom(this.state$),
      switchMap(([invalidateCahce, { status, cachedAt }]) =>
        iif(
          () =>
            invalidateCahce ||
            status !== HttpStatus.Success ||
            Date.now() - (cachedAt ?? 0) > 300000,
          of(void 0).pipe(
            tap(this.setLoading),
            switchMap(() => this.projectsService.all()),
            tapResponse(this.setSuccess, this.setError)
          ),
          EMPTY
        )
      )
    )
  );

  constructor(
    private readonly projectsService: ProjectsService,
    private readonly authStore: AuthStore
  ) {
    super({ status: HttpStatus.Idle });
  }

  private readonly setLoading = this.updater<void>((state) => ({
    ...state,
    status: HttpStatus.Loading,
  }));

  private readonly setError = this.updater<HttpErrorResponse>((_, error) => ({
    cachedAt: Date.now(),
    status: HttpStatus.Error,
    error,
  }));

  private readonly setSuccess = this.updater<ProjectAndSenarios[]>(
    (_, projects) => ({
      cachedAt: Date.now(),
      status: HttpStatus.Success,
      data: projects.reduce(
        (acc, project) => ({
          ...acc,
          [project.details.ProjectNumber]: project,
        }),
        {}
      ),
    })
  );
}
